@import '../../../assets/css/variables';

.m-user-info {
  width: 100%;
  display: inline-flex;
  &__text {
    width: calc(100% - 53px);
    margin: 13px 0 0 16px;
    letter-spacing: 0.05em;
    div {
      height: 20px;
      width: 100%;
      overflow: hidden;
    }
  }
}
