@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.m-menuitem {
  $r: &;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 0 32px 0;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 4px;
    background: $color-111;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform .5s;
  }

  &--active {
    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  @include not-sp {
    &:hover {
      &::after {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }

  & div {
    border-left: 1px dotted black;
    height: 25px;
    font-size: 16px;
    font-weight: bold;
    & p {
      margin: unset;
      font-size: 0.8em;
      font-weight: normal;
    }
  }

  &--card {
    padding: 0 0 2px 0;

    & div {
      font-size: 14px;
      height: 17px;
    }
  }

  @include sp {
    padding: 11px 0 20px 0;
    &::after {
      top: 0;
      bottom: auto;
    }
    &--active {
      border: none;
    }

    &--card {
      &::after {
        top: auto;
        bottom: 0;
      }
    }
    & div {
      font-size: 14px;
      height: 17px;
    }
  }
}
