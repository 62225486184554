@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.o-menulist {
    .top {
      margin-bottom: 40px;
    }
    .middle {
      margin-bottom: 39px;
    }
    .bottom {
      margin-bottom: 35.5%;
    }

    @include tablet {
        .top,
        .middle {
        margin-bottom: 37px;
        }
        .bottom {
        margin-bottom: 35.5%;
        }
    } 
}
