@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.a-leftmenu-item {
  /*margin-bottom: 6px;*/
  line-height: 1.3;
  display: inline-block;
  max-width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
  &--group {
    cursor: pointer;
    position: relative;
    text-align: left;
    &__title {
      position: relative;
      width: 100%;
      margin-bottom: 3px;
      font-weight: bold;
      box-sizing: border-box;
      letter-spacing: -0.05em;
      &::after {
        background: url(../../../assets/imgs/group-arrow-up.png) no-repeat;
        background-size: cover;
        transform: rotate(180deg);
        content: '';
        width: 9px;
        height: 6px;
        margin: 0 0 3px 13px;
        display: inline-block;
      }

      &--show {
        display: none;
      }
      &--hidden {
        &::after {
          transform: rotate(360deg);
          margin: 0 0 2px 9px;
        }
      }
    }
    & a {
      text-align: left;
      /*margin-bottom: 6px;*/
    }
  }

  &span {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      right: 0;
      height: 1px;
      background: $color-111;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .5s;
    }
  }

  &---6 {
    letter-spacing: -0.06em;
  }
  &---5 {
    letter-spacing: -0.05em;
  }
  &---3 {
    letter-spacing: -0.03em;
  }
  &--5 {
    letter-spacing: 0.05em;
  }
  &--8 {
    letter-spacing: 0.08em;
  }
  &--10 {
    letter-spacing: 0.1em;
  }
  @include not-sp {
    &:hover > span::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
  @include sp {
    &--group {
      &__title {
        &::after {
          margin: 0 0 3px 24px;
        }
        &--hidden {
          &::after {
            transform: rotate(360deg);
            margin: 0 0 2px 24px;
          }
        }
      }
    }
  }
}

.a-leftmenu-menuitem {
  position: relative;

  .btn-favorite {
    display: none;
    margin-right: 4px;
    cursor: pointer;
    position: absolute;
    font-size: 1.3em;
    top: -5px;
    margin-left: 4px;

    color:#ffffff;
    text-shadow:1px 1px 0 #888, -1px -1px 0 #888,
    -1px 1px 0 #888, 1px -1px 0 #888,
    0px 1px 0 #888,  0px -1px 0 #888,
    -1px 0px 0 #888, 1px 0px 0 #888;
  }
  .added {
    color:#ffff00;
    text-shadow:1px 1px 0 #888, -1px -1px 0 #888,
    -1px 1px 0 #888, 1px -1px 0 #888,
    0px 1px 0 #888,  0px -1px 0 #888,
    -1px 0px 0 #888, 1px 0px 0 #888;
  }
}

.a-leftmenu-menuitem:hover .btn-favorite{
  display: inline;
}
