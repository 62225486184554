@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.m-headinglist {
  display: flex;
  align-items: center;
  position: relative;
  height: 34px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 16px;
    /*margin: 0 0 7px 0px;*/

    &--logout {
      cursor: pointer;

      &:hover {
        font-weight: bold;
      }
    }

    & > img {
      margin-right: 10px;
    }
    &--lap > img {
      margin-right: 8px;
      width: 27px;
    }
  }
  &--line {
    border-bottom: 1px dotted $black;
  }

  &--logout {
    span {
      font-size: 14px;
    }
  }
  & > button {
    position: absolute;
    right: -8px;
    top: 1px;
    & > img {
      transform: rotate(180deg);
    }
  }

  &--show > button > img {
    transform: rotate(360deg);
  }

  @include tablet {
    width: 88%;
  }

  @include sp {
    height: 38px;
    &__title {
      &--lap,
      &--file,
      &--other {
        .a-icon {
          width: 31px;
          margin: 0 8px;
        }
        margin-bottom: 11px;
        .a-text {
          font-size: 14px;
        }
      }
      &--file,
      &--other {
        .a-icon {
          margin: 0 10px 0 4px;
        }
      }
    }
    & > button {
      .a-icon {
        width: 31px;
      }
      right: 8px;
      top: -1px;
    }
  }
}
