@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.logout{
  width: 100%;
  height: 100vh;
  position: relative;
  &__centerblock{
    width: 190px;
    height: 110px;
    position: absolute;
    top: 25%;
    left: 0px;
    right: 0px;
    margin: auto;

    div {
      display: inline-block;
      text-align: center;
      margin-left: 10px;

      P{
        height: 20px;
        margin: unset;
      }
      .ball {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: solid 1px #000;
        margin-left: 0px;
        background-color: white;
        animation: flash 4s ease infinite forwards;
      }
    }
    div:nth-child(1){
      p:first-child {
        text-decoration: underline;
      }
      .ball{
      animation-delay: 0s;
     }
    }
    div:nth-child(2) .ball{
      animation-delay: 0.5s;
    }
    div:nth-child(3) .ball{
      animation-delay: 1s;
    }
    div:nth-child(4) .ball{
      animation-delay: 1.5s;
    }
    div:nth-child(5) .ball{
      animation-delay: 2s;
    }
    div:nth-child(6) .ball{
      animation-delay: 2.5s;
    }
    div:nth-child(7) .ball{
      animation-delay: 3s;
    }
    div:nth-child(8) .ball{
      animation-delay: 3.5s;
    }
  }

  @keyframes flash {
    0% { background: white; }
    12% { background: gray; }
    24% { background: white; }
    100% { background: white; }
  }

}