@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.m-salescardcontent {
  height: 100%;
  position: relative;
  $root: &;
  &__body {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 55%;

    & .graph-block{
      width: 100%;
      height: 100%;
    }

    &__left {
      width: 55%;
      text-align: center;

      & > span {
        display: block;
      }

      &__graph {
        &--週 {
          padding: 14px 13px 0 13px;
          width: 120px;
          margin-bottom: 10px !important;
        }

        &--日 {
          width: 80px;
          margin: 0px 10px -3px 4px;
        }
      }
    }
    &__right{
      width: 45%;
    }
  }
  & .m-division-content {
    & .m-division-content_pulldown{
      margin-left: 10px;
      display: inline-block;

      select{
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 55%;
        min-width: 190px;
        line-height: 21px;
        border: none;
        border-bottom: solid 1px #888;
        background-color: #fff;
        border-radius: 0px;
        outline: none;

      option[data-division = "0"] {
          background-color: #D7EEFF;
        }
      }
      &::after{
        content: "▼";
        font-size: 12px;
        margin-left: -16px;
        pointer-events: none;
      }
    }
    & .m-division-content_notice{
      display: inline-block;
      top: 32px;
      right: 0px;
      margin: auto;
      position: absolute;
      height: 45px;
      text-align: right;
    }
  }
  &__footer {
    position: absolute;
    bottom: 5px;
    width: 100%;

    & .a-textlink {
      position: absolute;
      right: -2px;
      bottom: -2px;
    }

    &__tab {
      display: flex;
      position: relative;
      margin-left: 1px;
      &__wrap {
        padding: 0 11px;

        .m-menuitem {
          padding: 2px 0;

          & > div {
            border-left: none;
            letter-spacing: 1px;
            padding-bottom: 4px;
          }

          &::after {
            height: 2px;
          }
        }

        &::after {
          content: '';
          background: url('../../../assets/imgs/dotted.svg');
          width: 1px;
          height: 16px;
          position: absolute;
          left: 35px;
          bottom: 4px;
        }
      }
    }
  }
  &--focus {
    & .a-textlink {
      display: none;
    }
    #{$root} {
      &__body {
        height: 89%;
        flex-direction: column;
        align-items: center;
        &__left {
          &__graph {
            &--週 {
              width: 237px;
            }
            &--日 {
              width: 237px;
            }
          }
        }
        &__right {
          margin-bottom: 30px;
          margin-left: 20px;
          span {
            font-size: 32px;
          }
        }
      }
      &__footer {
        display: flex;
        justify-content: center;
      }
    }
    .m-cardheader {
      justify-content: flex-start;
      &__content {
        span + span {
          display: none;
        }
      }
    }
  }
  @include large-pc {
    &__body {
      &__left {
        &__graph {
          &--週 {
            width: 155px;
          }
          &--日 {
            width: 125px;
          }
        }
      }
    }
  }

  @include tablet {
    &__body {
      &__left {
        display: none;
      }
      &__right {
        margin-left: 23px;
      }
    }
    & .m-division-content {
      & .m-division-content_notice{
          display: none;
      }
    }
  
    &--focus {
      padding: 0;
      #{$root} {
        &__body {
          &__right {
            margin-right: -10px;
            line-height: 0;
          }
        }
      }
      .m-cardheader {
        margin-top: -11px;
        margin-left: -1px;
      }
    }
    &__footer {
      bottom: 5px;
      &__tab {
        margin-left: -4px;
      }
      & .a-textlink {
        right: -4px;
      }
    }
  }
  @include sp {
    &__body {
      padding: 0;
      &__left {
        margin-right: 9px;
        &__graph {
          &--週 {
            padding: 0 9px 0 10px;
            width: 114px;
          }
          &--日 {
            width: 100px;
            margin: 7px 10px 17px -4px;
          }
        }
      }

      &__right {
        display: block;
        height: 85px;
        margin-right: 20px;
        position: relative;
        & span {
          font-size: 24px;
          letter-spacing: 0.01em;
        }
        & div {
          &::after {
            font-size: 12px;
          }
        }
      }
    }
    &__footer {
      position: unset;
      &__tab {
        position: absolute;
        top: 4px;
        right: 8px;
        &__wrap {
          &:nth-of-type(2) {
            padding-right: 7px;
            padding-left: 17px;
          }
          &::after {
            height: 14px;
            left: 40px;
          }
          .m-menuitem > div {
            padding-bottom: 3px;
          }
        }
      }
      & .a-textlink {
        right: 4px;
      }
      &::after {
        height: 14px;
      }
    }
    &--focus {
      #{$root} {
        &__footer__tab {
          top: 92%;
          left: 36%;
        }
        &__body {
          &__left {
            &__graph {
              &--週 {
                padding: 0 16px 0 0;
                margin-top: 10px;
              }
              &--日 {
                margin: 11px 10px 6px -9px;
              }
            }
          }
          &__right {
            margin-right: 19px;
            margin-top: -5px;

            .a-numsymbol--18 {
              margin-left: 28px;
            }
          }
        }
      }
    }
  }
}