@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.store-page {
  padding-top: 24px;
  &__group {
    display: flex;
    justify-content: space-between;

    &:first-of-type {
      .o-card {
        .wrapper {
          border-top: 1px solid;
        }
      }
    }

    & > a {
      width: calc(47.5% - 1px);
      position: relative;
      &:last-of-type {
        & .o-card {
          .m-storecardcontent {
            & > .a-btn {
              margin: -2px 11% 0 2px;
            }
          }
        }
      }
    }
  }

  @include sp {
    padding-top: 21px;
    &__group {
      display: block;
      & > a {
        width: 100%;
        &:last-of-type {
          & .o-card {
            .m-storecardcontent {
              & > .a-btn {
                margin: 0 2% 0 6px;
              }
            }
          }
        }
      }
      &:first-of-type {
        .o-card {
          .wrapper {
            border-top: none;
          }
        }
      }
    }
  }

  @include small-sp {
    font-size: 12px;
  }
}
