@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.a-text {
  &--sub {
    color: $grey_77;
  }
  &--12 {
    font-size: 12px;
  }
  &--16 {
    font-size: 16px;
  }
  &--18 {
    font-size: 18px;
  }
  &--24 {
    font-size: 24px;
  }
  &--40 {
    font-size: 40px;
  }
  &--italic {
    font-style: italic;
  }
  &--bold {
    font-weight: bold;
  }


}
