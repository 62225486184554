@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.m-tab {
  $r: &;
  height: 32px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  border: 1px dotted rgba(17, 17, 17, 0);
  &::before, &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: $color-111;
    transition: transform .3s;
    transform: scaleX(0);
  }
  &::before {
    top: -1px;
  }
  &::after {
    bottom: -1px;
  }

  &:first-of-type div {
    border: none;
  }
  &:last-of-type div {
    border-right: 1px dotted;
  }

  & div {
    font-weight: bold;
    border-left: 1px dotted;
    height: 32px;
    line-height: 32px;
    position: relative;
    &::before, &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 2px;
      width: 1px;
      background-color: $color-111;
      transition: transform .1s;
      transform: scaleY(0);
    }
    &::before {
      top: 8px;
      left: 0;
    }
    &::after {
      right: 0;
    }
  }

  &:hover {
    border-color: rgba(17, 17, 17, 1);
    & + #{$r},
    & {
      div {
        padding-left: 1px;
        border-left: none;
      }
    }
  }
  &--active {
    padding: 0;
    &::before, &::after {
      transform: scaleX(1);
      transition: transform .3s;
    }
    &::before {
      top: -1px;
      transform-origin: left;
      transition-delay: 0;
    }
    &::after {
      bottom: -1px;
      transform-origin: right;
      transition-delay: .4s;
    }
    & div {
      border-right: none !important;
      transition: transform .1s;
      &::before, &::after {
        transform: scaleY(1);
      }
      &::before {
        top: 8px;
        left: 0;
        transform-origin: bottom;
        transition-delay: .7s;
      }
      &::after {
        right: 0;
        transform-origin: top;
        transition-delay: .3s;
      }
    }
    &:hover {
      border-color: rgba(17, 17, 17, 0);
    }
    & + #{$r},
    & {
      div {
        padding-left: 1px;
        border-left: none;
      }
    }
  }
  & .a-notify {
    position: absolute;
    top: 4px;
    display: inline;
  }

  @include tablet {
    min-width: 82px;
  }
  @include sp {
    min-width: 74px;
  }
}
