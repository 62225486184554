@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.m-storecardcontent {
  display: flex;
  align-items: center;
  position: relative;
  $r: &;
  & > .a-btn {
    margin: -2px 10% 0 9px;
    width: 20px;
    height: 20px;
  }
  &__content {
    width: 100%;
    line-height: 1.5;
    margin-top: -2px;
    &__description {
      font-size: 12px;
    }
  }
  &__link {
    display: flex;
    align-items: center;
    margin: 2px 3.4% 0 0;
  }
  &--drop {
    #{$r}__content {
      margin-top: 12px;
      & span {
        margin-left: -1px;
        display: block;
      }
      &__description {
        letter-spacing: 0.02em !important;
      }
    }
  }
  &--5 {
    #{$r}__content {
      & span {
        letter-spacing: 0.05em;
      }
    }
  }
  &--6 {
    #{$r}__content {
      & span {
        letter-spacing: 0.06em;
      }
    }
  }
  &--7 {
    #{$r}__content {
      & span {
        letter-spacing: 0.07em;
      }
    }
  }
  @include sp {
    margin-top: -4px;
    margin-left: -3px;
    & > .a-btn {
      margin: 0 2% 0 6px;
      width: 20px;
      height: 20px;
    }
    &__content {
      font-weight: bold;
      width: 100%;
      line-height: 1.5;
      font-size: 14px;
    }
    &__link {
      margin: 0px 1.2% 0 0;
    }

    &--drop {
      #{$r}__content {
        margin-top: 0;
        & span {
          display: inline-block;
        }
        &__description {
          font-size: 12px;
          padding-left: 15px;
          letter-spacing: -0.04em !important;
        }
      }
    }
  }
}
