@import '../../assets/css/variables';
@import '../../assets/css/mixins';

.layout {
  .body {
    padding: 12px 4% 24px 4%;
    display: flex;
    height: 100%;
    &__leftmenu {
      position: relative;
      width: 25%;
      max-width: 440px;
      min-width: 200px;
      box-sizing: border-box;
      padding-right: 6%;
      &__profile {
        box-sizing: border-box;
        margin-bottom: 26px;
        &__info {
          margin-bottom: 17px;
        }
        &__working-time {
          font-size: 12px;
          letter-spacing: 0.06em;
          & div {
            margin-right: 18px;
            display: inline-block;
            & > span {
              margin-left: 2px;
            }
          }
        }
      }
      &__menu {
        .top {
          margin-bottom: 40px;
        }
        .middle {
          margin-bottom: 39px;
        }
        .bottom {
          margin-bottom: 35.5%;
        }
        .m-headinglist--logout {
          margin-bottom: 21%;
        }
      }
      @include sp {
        display: none;
        &__menu {
          display: block;
        }
        &__profile {
          display: none;
        }
        &--show {
          display: block;
        }
      }
    }
    &__signature {
      position: absolute;
      bottom: -5px;
      letter-spacing: 0.12em;
      font-size: 12px;
    }
    &__content {
      box-sizing: border-box;
      width: 75%;
      &__function-list {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;
        &__left {
          display: flex;
          & a {
            margin-right: 8px;
          }
          @include tablet {
            & a {
              margin-right: 5px;
            }
          }
          .item {
            position: relative;
            &__option {
              visibility: hidden;
              opacity: 0;
              left: -145%;
              top: 139%;
              transition: all 0.3s;
              position: absolute;
              z-index: 10;
              &__wrapper {
                width: 205px;
                border: 1px solid;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: $white;
                box-sizing: border-box;
                & > a {
                  margin: 0;
                  height: 31px;
                  width: 100%;
                  padding: 0 7px;
                  box-sizing: border-box;
                  letter-spacing: -0.07em;
                  &:first-of-type {
                    height: 32px;
                    letter-spacing: 0.1em;
                    &::after {
                      content: '';
                      display: block;
                      position: absolute;
                      background: $white;
                      left: 85px;
                      top: -5px;
                      bottom: 100%;
                      transform: rotate(45deg);
                      width: 9px;
                      height: 9px;
                      border-top: 1px solid;
                      border-left: 1px solid;
                    }
                  }
                  &:not(:last-child) > span {
                    border-bottom: 1px dotted;
                  }
                  & > span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                  }
                  &:hover {
                    background: $color-111;
                    color: $white;
                  }
                  &:first-of-type:hover {
                    &::after {
                      border: 0;
                      background: $color-111;
                    }
                  }
                }
              }
              &::after {
                background: url(../../assets/imgs/card-shadow.png) no-repeat;
                height: 100%;
                width: 100%;
                content: '';
                background-size: cover;
                z-index: -1;
                position: absolute;
                bottom: -3px;
                right: -3px;
                opacity: 0;
                transition: opacity 0.3s;
              }
            }
            &:hover {
              & > div {
                visibility: visible;
                opacity: 1;
                transition: opacity 0.3s;
                &::after {
                  opacity: 1;
                  transition: opacity 0.3s;
                }
              }
            }
          }
        }
      }
    }

    @include large-pc {
      &__leftmenu {
        width: 25%;
      }
      &__content {
        width: 75%;
      }
    }
    @include tablet {
      padding: 12px 5% 24px 5%;
      &__leftmenu {
        width: 25%;
        padding-right: 2%;
        &__profile {
          margin-bottom: 26px;
          &__info {
            margin-bottom: 17px;
          }
          &__working-time {
            .a-text--bold {
              margin-left: 2px;
            }
          }
        }
        .top,
        .middle {
          margin-bottom: 37px;
        }
        .bottom {
          margin-bottom: 35.5%;
        }
      }
      &__content {
        width: 75%;
        &__function-list {
          &__left {
            & > button {
              margin-right: 29px;
            }
            .item {
              &__option {
                left: -250%;
                &__wrapper {
                  & > a {
                    &:first-of-type {
                      &::after {
                        left: 97px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    @include sp {
      padding: 0;
      position: relative;
      margin-bottom: 60px;
      &__leftmenu {
        padding: 0;
        max-width: unset;
        width: 100%;
        height: 100%;
        z-index: 1;
        &__menu {
          padding: 16px 16px;
          background: white;
          height: 100%;
          .m-headinglist--logout {
            display: none;
          }
        }
      }
      &__content {
        padding: 0 16px;
        width: 100%;
        @include sp {
          &__function-list {
            display: none;
          }
        }
      }
      &__signature {
        display: none;
      }
    }
  }
}
