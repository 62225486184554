@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.o-modal {
  &::after {
    position: fixed;
    background: $color-41;
    content: '';
    width: 100%;
    height: 100%;
    opacity: 0.5;
    left: 0;
    top: 0;
    z-index: 998;
  }
  &__wrapper {
    z-index: 999;
    width: 340px;
    height: 400px;
    left: calc(50% - 190px);
    position: fixed;
    top: 11.7%;
    background: white;
    padding: 20px;
    &__close {
      position: absolute;
      right: 18px;
      top: 20px;
      cursor: pointer;
      z-index: 999;
    }
  }

  @include sp {
    &__wrapper {
      height: 391px;
      width: 80.7%;
      left: 4.3%;
      top: 11.7%;
      padding-top: 9px;
    }
  }
  @include small-sp {
    &__wrapper {
      width: 77.7%;
      left: auto;
    }
  }
}
