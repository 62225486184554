@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.top-page {
  &__menu {
    margin-bottom: 40px;

    .menu__item {
      width: 48.5%;
    }

    &__group {
      display: flex;
      justify-content: space-between;

      &--bottom {
        .o-card {
          .wrapper {
            border-top: 0;
          }
        }
      }
    }
  }
  &__adjust-button {
    position: fixed;
    right: calc(4% - 14px);
    bottom: 5vh;
  }
  @include sp {
    &__menu {
      &__group {
        &--top {
          display: block;

          .menu__item {
            width: 100%;
            margin-bottom: 12px;
          }
        }
        &--bottom {
          .o-card {
            .wrapper {
              border-top: 0;
            }
          }
        }
      }
    }
    &__adjust-button {
      display: none;
    }
  }
}
