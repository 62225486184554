@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.m-noticecardcontent {
  .m-noticeblock{
    position: relative;
    height: 165px;

    .contents {
      width: 100%;
      height: 100%;
      max-height: 135px;
      overflow-y: hidden;
      * {
          margin: unset;
      }
      a {
          color: blue;
          text-decoration: underline;
      }
    }

    .footer {
      width: 100%;
      height: 15%;
      font-size: 18px;
      position: absolute;
      bottom: 0px;
      display: flex;
      overflow-y: hidden;

      &__left{
        margin-left: 5%;
        width: 40%;
        text-align: left;
      }

      &__right{
        margin-right: 5%;
        width: 50%;
        text-align: right;
      }
    }

  }

  .notice-myDots{
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      button {
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
      }
      button:before {
        font-family: 'slick';
        font-size: 6px;
        line-height: 20px;
        position: absolute;
        top: 0;
        left: 0;
        content: '•';
        text-align: center;
        opacity: .25;
        color: black;
        -webkit-font-smoothing: antialiased;
      }
    }
    li.slick-active {
      button:before {
        opacity: 1;
      }
    }
  }

  @include sp {
    .m-noticeblock{
      height: 125px;
      &__body{
        .contents {
          max-height: 85px;
        }
      }
      .footer {
        height: 24px;
      }
    }
  }
}
