@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.blue-background-class {
	background-color: #C8EBFB;
}

.m-vertical-menu {
  &__heading {
    margin-bottom: 11px;
    &__title {
      position: relative;
      width: 100%;
      margin-bottom: 3px;
      font-weight: bold;
      box-sizing: border-box;
      letter-spacing: -0.05em;
      &::after {
        background: url(../../../assets/imgs/group-arrow-up.png) no-repeat;
        background-size: cover;
        transform: rotate(180deg);
        transition-duration: 0.5s;
        content: '';
        width: 9px;
        height: 6px;
        margin: 0 0 3px 13px;
        display: inline-block;
      }
      &--hidden {
        &::after {
          transform: rotate(360deg);
          transition-duration: 0.5s;
          margin: 0 0 2px 9px;
        }
      }
    }
  }

  &__item {
    opacity: 1;
    visibility: visible;
    transition: 0.8s;
    &--hidden {
      opacity: 0;
      transition: 0.8s;
      visibility: hidden;
    }
  }
  div {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include sp {
    &__heading {
      margin-bottom: 21px;
      display: none;
    }
    &__item {
      padding-left: 10px;
      font-size: 13px;
      & .a-leftmenu-item {
        margin-bottom: 20px;
      }
      margin-bottom: 74px;
    }
  }
}
