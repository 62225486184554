@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.o-header {
  height: 80px;
  display: flex;
  border-bottom: 1px solid;
  padding: 0 4%;
  box-sizing: border-box;

  .a-usericon {
    display: none;
  }

  &__logo {
    width: 32%;
    position: relative;
    max-width: 440px;

    &__image {
      width: 189px;
      height: 45px;
      background: url(../../../assets/imgs/logo.jpg) no-repeat;
      background-size: cover;
      position: absolute;
      top: 25%;
    }

    .logo-staging {
      width: 75px;
      position: absolute;
      top: 44px;
      left: 28px;
      margin: auto;
      background-color: white;
      font-size: 16px;
      font-family: Arial;
    }
  }

  &__menu {
    width: 68%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__left {
      width: 70%;

      &__item {
        width: 25%;
        height: 100%;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;

        &:first-of-type div {
          border-left: 0;
        }
      }
    }

    &__right {
      margin: 20px -1px 0 0;
      width: 32%;
      display: flex;
      justify-content: right;
      gap: 14px;
    }
  }

  &__weather {
    width: 70px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    pointer-events: all;
    position: absolute;
    right: 26%;
    top: 20px;
  }

  .button-item {
    flex-basis: 90%;
    max-width: 250px;
    min-width: 140px;
  }

  .item {
    display: flex;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: relative;

    /* 新着の赤丸 */
    .is-new {
      width: 13px;
      height: 13px;
      background-color: red;
      border-radius: 50%;
      z-index: 2;
      cursor: pointer;
      position: absolute;
      right: -3px;
      top: -2px;
    }

    /* コンテンツ */
    img {
      z-index: 1;
      width: auto;
      height: 45px;
    }
  }

  @include large-pc {
    &__logo {
      width: 30%;
    }

    &__menu {
      width: 70%;

      /* &__right {
        max-width: 250px;
      } */
    }
  }

  @include tablet {
    padding: 0 5%;

    &__logo {
      width: 38%;

      &__image {
        width: 189px;
      }
    }

    &__menu {
      &__left {
        width: 52%;
      }

      &__right {
        width: 34.6%;
        margin-right: 4px;
      }
    }
  }

  @include sp {
    width: 100%;
    padding: 0;
    border: 0;
    height: 52px;

    &__logo {
      padding: 0 16px;
      width: inherit;
      height: 54px;
      background: $white;
      border-bottom: 1px solid;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: unset;
      z-index: 99;

      &--fixed {
        position: fixed;
      }

      &__image {
        width: 118px;
        height: 28px;
        position: unset;
        margin-top: 7px;
      }

      .logo-staging {
        top: 30px;
        left: 0px;
        right: 0px;
        font-size: 14px;
        text-align: center;
      }
    }

    &__menu {
      width: inherit;
      height: 51px;
      background: $white;
      border-top: 1px solid;
      position: fixed;
      bottom: 0;
      z-index: 99;

      &__left {
        width: 100%;
        height: 51px;

        &__item {
          display: inline-block;
          width: 33.3%;

          &:last-of-type {
            display: none;
          }
        }
      }

      &__right {
        display: none;
      }
    }

    &__weather {
      border-top: 1px solid;
      /*position: fixed;*/
      bottom: 0;
      display: inline-block;
      width: 33.3%;
      pointer-events: none;
      justify-content: space-between;
      top: -30px;
      right: 20px;
      z-index: 98;
    }

    .a-usericon {
      display: block;
    }
  }
}