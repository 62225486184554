@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.media-page {
  padding: 24px 0 0 2px;
  &__pickup {
    margin-bottom: 31px;
  }
  /*
  &__slide {
    margin-bottom: 31px;
  }
  */
  &__news {
    margin-bottom: 31px;
  }
  &__media {
    &__heading {
      margin-bottom: 18px;
      & .a-btn {
        width: 2px;
        padding: 0 11px 0 1px;
        cursor: pointer;
        .a-icon {
          width: 4px;
        }
      }
      & span:first-of-type {
        margin-right: 7px;
        letter-spacing: -0.05em;
      }
    }
    &__content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &__item {
        position: relative;
        width: calc(49% - 2px);
        .box {
          width: 100%;
          border: 1px solid;
          box-sizing: border-box;
          margin-bottom: 1px;
          &__header {
            display: flex;
            margin: 3% 5% 0px;
            & > img {
              width: 100%;
              height: 100%;
            }
          }
          &__content {
            padding: 11px 15px 2px 15px;
            &__item {
              margin-bottom: 3%;
              display: inline-block;
              width: 48%;
              &:nth-child(odd) {
                margin-right: 4%;
              }
              & > img {
                width: 100%;
                display: block;
              }
            }
          }
        }
        & > img {
          width: 100%;
        }
        & .a-textlink {
          float: right;
          display: flex;
          letter-spacing: 0.03em;
          &::after {
            margin-right: -3px;
          }
        }

        &--instagram {
          .box {
            margin-bottom: 3px;
            &__header {
              margin: 6% 0 19px 5%;
              box-sizing: border-box;
              justify-content: unset;
              width: 100%;
              &__left {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid $color-dc;
                border-radius: 50%;
                width: 52px;
                height: 52px;
                img {
                  width: 46%;
                  filter: invert(1);
                }
              }
              &__right {
                margin: 4px 0 0 16%;
                width: 43.4%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                & img {
                  display: block;
                  width: 100%;
                  margin-bottom: 5px;
                }
                & span {
                  display: block;
                  margin-left: -1px;
                  letter-spacing: 0.04em;
                }
              }
            }
            &__content {
              padding: 11px 15px 11px 16px;
              &__item {
                width: 32.7%;
                margin-bottom: 0.5px;
                &:nth-child(odd) {
                  margin-right: 0;
                }
                &:nth-child(3n + 2) {
                  margin-right: 0.9%;
                  margin-left: 0.9%;
                }
              }
            }
          }
          & .a-textlink {
            letter-spacing: 0.07em;
            &::after {
              margin-left: 1px;
              margin-right: -4px;
            }
          }
        }
      }
    }
  }
  @include sp {
    padding: 22px 0 0 0;
    &__slide {
      margin-bottom: 30px;
    }
    &__news {
      margin-bottom: 13px;
    }
    &__media {
      &__heading {
        margin-bottom: -8px;
      }
      &__content {
        flex-direction: column;
        align-items: center;
        &__item {
          width: 80%;
          margin-bottom: 22px;
          & .a-textlink {
            margin: 0 16px 0 0;
            letter-spacing: 0.02em;
            &::after {
              margin-top: 3px;
              margin-left: 3px;
              margin-right: 0;
            }
          }
          .box {
            border: none;
            margin-bottom: 0;
            &__header {
              margin: 0px 41px 0px;
            }
            &__content {
              padding: 3px 20px 0;
              margin-bottom: 0px;
              &__item {
                width: 49%;
                margin: 0;
                &:nth-child(odd) {
                  margin-right: 2%;
                }
              }
            }
          }
          &--instagram {
            margin-bottom: 0;
            .box {
              &__header {
                margin: -1px 0 4.4% 10%;
                &__left {
                  width: 59px;
                  height: 59px;
                  & > img {
                    height: 54px;
                  }
                }
                &__right {
                  margin: 3px 0 0px 30px;
                  width: 37.4%;
                  & > img {
                    margin-bottom: 10px;
                  }
                  & > span {
                    margin: 0;
                  }
                }
              }
              &__content {
                padding: 5px 20px 0;
                margin-bottom: -1px;
                &__item {
                  width: 32%;
                  margin-bottom: 1px;
                  &:nth-child(odd) {
                    margin-right: 0;
                  }
                  &:nth-child(3n + 2) {
                    margin-right: 2%;
                    margin-left: 2%;
                  }
                }
              }
            }
            & .a-textlink {
              letter-spacing: 0.05em;
              &::after {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}
