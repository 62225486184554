@import '../../../assets/css/variables';

.a-notify {
  background: $red-dc1e;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: block;

  &--8 {
    width: 8px;
    height: 8px;
  }
  &--text {
    width: 37px;
    height: 23px;
    background: url(../../../assets/imgs/new.svg) no-repeat;
    border-radius: 0;
  }
}
