@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';
.a-usericon {
  $sp: 36px;
  $pc: 40px;
  position: relative;
  img {
    border-radius: 50%;
    width: $pc;
    height: $pc;
  }
  &::after {
    content: '';
    background: url(../../../assets/imgs/shadow.png) no-repeat;
    background-size: cover;
    position: absolute;
    z-index: -1;
    bottom: 1px;
    right: -2px;
    width: $pc;
    height: $pc;
  }
  @include sp {
    margin: 6px 2px 0 -15px;
    img {
      width: $sp;
      height: $sp;
    }
    &::after {
      width: $sp;
      height: $sp;
    }
  }
}
