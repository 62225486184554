@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.o-pickup {
  ul {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;

    padding: 0;
    margin: 0;

    li{
      display: inline-block;
      width: 30%;
      margin-bottom: 20px;

      .o-pickup__contents {
        width: 100%;
        transition: transform 0.3s;
        box-shadow: 0 0 0 0 #FFF;

        &__banner{
          width: 100%;
          height: auto;
          margin-bottom: 3px;
    
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }

        &__footer {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: medium;
        }
      }
      .o-pickup__contents:hover {
        transform: translate3d(-2px, -3px, 0);
        box-shadow: 4px 4px 4px 1px #aaa;
      }
    }
  }

  @include sp {
  }
}
