@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.o-card {
  height: 201px;
  position: relative;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s;

  .wrapper {
    width: 100%;
    height: inherit;
    padding: 5px 13px 5px 6px;
    background: $white;
    box-sizing: border-box;
    border-top: 1px solid $color-111;
    border-bottom: 1px solid $color-111;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
  }

  &--notice {
    .wrapper {
      overflow-x: hidden;
    }
  }

  &::after {
    background: url(../../../assets/imgs/card-shadow.png) no-repeat;
    height: 100%;
    width: 100%;
    content: '';
    background-size: cover;
    z-index: -1;
    position: absolute;
    bottom: -6px;
    right: -6px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &--store {
    height: 97px;
    .wrapper {
      padding: 0;
      justify-content: center;
      border-bottom: 1px dotted $color-111;
      border-top: none;
    }
  }
  @include pc {
    &:hover {
      transform: translate3d(-4px, -6px, 0);
      .wrapper {
        border: 1px solid;
      }
      &::after {
        opacity: 1;
      }
    }
    &--approved,
    &--tasks {
      &:hover {
        transform: translate(-4px, -7px);
      }
    }
    &--store:hover {
      .m-storecardcontent {
        & > .a-btn {
          margin: -2px 8% 0 7px;
        }
      }
    }
  }

  @include tablet {
    .wrapper {
      padding: 5px 16px 5px 0px;
    }
  }

  @include sp {
    .wrapper {
      padding: 0;
    }

    &--weather {
      height: 45px;
      width: calc(100% + 32px);
      transform: translateX(-16px);
      .wrapper {
        background: $color-f3;
        border: none;
      }
      &::after {
        bottom: unset;
        right: unset;
      }
    }

    &--sales,
    &--notice {
      height: 154px;
      .wrapper {
        border-top: none;
        border-bottom: 1px solid $color-111 !important;
      }
    }
    &--tasks,
    &--approved {
      border-top: none;
      height: 99px;
      width: 98%;
    }

    &--store {
      height: 59px;
    }
  }
}
