@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.m-righticons {
  display: flex;
  justify-content: flex-end;

  .item {
    position: relative;
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 3px;
    text-align: center;

    /* ホバー */
    &__option {
      visibility: hidden;
      opacity: 0;
      left: -200%;
      top: 139%;
      transition: all 0.3s;
      position: absolute;
      z-index: 10;

      &__wrapper {
        width: 170px;
        border: 1px solid;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $white;
        box-sizing: border-box;

        &>a {
          margin: 0;
          height: 31px;
          width: 100%;
          padding: 0 7px;
          box-sizing: border-box;
          letter-spacing: -0.07em;

          &:first-of-type {
            height: 32px;
            letter-spacing: 0.1em;

            &::after {
              content: '';
              display: block;
              position: absolute;
              background: $white;
              left: 95px;
              top: -5px;
              bottom: 100%;
              transform: rotate(45deg);
              width: 9px;
              height: 9px;
              border-top: 1px solid;
              border-left: 1px solid;
            }
          }

          &:not(:last-child)>span {
            border-bottom: 1px dotted;
          }

          &>span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
          }

          &:hover {
            background: $color-111;
            color: $white;
          }

          &:first-of-type:hover {
            &::after {
              border: 0;
              background: $color-111;
            }
          }
        }
      }

      &::after {
        background: url(../../../assets/imgs/card-shadow.png) no-repeat;
        height: 100%;
        width: 100%;
        content: '';
        background-size: cover;
        z-index: -1;
        position: absolute;
        bottom: -3px;
        right: -3px;
        opacity: 0;
        transition: opacity 0.3s;
      }
    }

    &:hover {
      &>div {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s;

        &::after {
          opacity: 1;
          transition: opacity 0.3s;
        }
      }
    }

    /* 新着の赤丸 */
    .is-new {
      width: 13px;
      height: 13px;
      background-color: red;
      border-radius: 50%;
      z-index: 2;
      cursor: pointer;
      position: absolute;
      right: -3px;
      top: -2px;
    }

    /* コンテンツ */
    img {
      z-index: 1;
      width: auto;
      height: 40px;
    }

    img.project-a {
      border-radius: 50%;
      border: solid 1px #ccc;
      box-sizing: border-box;
      width: 40px;
      height: 40px;
    }
  }

  @include tablet {
    .item {
      height: 30px;
      width: 30px;
      margin-right: 3px;
      margin-left: 2px;

      /* ホバー */
      &__option {
        left: -400%;

        &__wrapper {
          &>a {
            &:first-of-type {
              &::after {
                left: 130px;
              }
            }
          }
        }
      }

      /* 新着の赤丸 */
      .is-new {
        width: 10px;
        height: 10px;
      }

      /* コンテンツ */
      img {
        height: 30px;
      }

      img.project-a {
        width: 30px;
        height: 30px;
      }
    }
  }
}