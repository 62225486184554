/*
---
name: ellipsis-multiline
category:
  - base/mixins
compile: true
---

使い方
```scss
.dummy-omit-text-cls-default {
  @include u-ellipsis-multiline();
}

.dummy-omit-text-cls-custom {
  @include u-ellipsis-multiline(3);
}
```

使用例
```ejs
  <p class="dummy-omit-text-cls-default">これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。</p>
  <p class="dummy-omit-text-cls-custom">これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。これはダミーテキストです。</p>
```

*/
/* 下記はstyleguide用のダミークラスなので、実装では使用しないでください */

@mixin small-sp {
  @media ($small-sp) {
    @content;
  }
}

@mixin middle-sp {
  @media ($middle-sp) {
    @content;
  }
}

@mixin sp {
  @media ($sp) {
    @content;
  }
}

@mixin tablet {
  @media ($tablet) {
    @content;
  }
}
@mixin not-sp {
  @media ($not-sp) {
    @content;
  }
}
@mixin pc {
  @media ($pc) {
    @content;
  }
}
@mixin large-pc {
  @media ($large-pc) {
    @content;
  }
}

@mixin u-fz($fontSize: false, $lineHeight: false, $letterSpacing: false) {
  @if ($fontSize) {
    font-size: $fontSize + px;
  }

  @if ($lineHeight) {
    line-height: ($lineHeight / $fontSize);
  }

  @if ($letterSpacing) {
    letter-spacing: $letterSpacing + em;
  }
}
