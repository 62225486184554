@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.p-login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  &__wrapper {
    margin-bottom: 9%;
    width: 400px;
    height: 400px;
    border: 2px solid;
    border-left: none;
    box-sizing: border-box;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      background-color: $color-111;
      top: 16px;
      left: 0;
      width: 2px;
      height: calc(100% - 16px);
    }
    &__content {
      padding: calc(20% - 1px) 20%;
      &__logo {
        margin-bottom: 131px;
      }
      &__google {
        margin-right: 3px;
        display: flex;
        justify-content: center;
        & > a {
          display: block;
          cursor: pointer;
          .o-card {
            height: 48px;
            width: 224px;
            .wrapper {
              border: 1px solid;
              justify-content: center;
            }
          }
        }
        &__text {
          text-align: center;
          position: relative;
          padding-left: 28px;
          margin-top: 1px;
          &:before {
            background: url(../../../assets/imgs/google-icon.png) no-repeat;
            width: 28px;
            height: 28px;
            content: '';
            position: absolute;
            background-size: contain;
            left: 3px;
            top: -4px;
          }
          & span {
            letter-spacing: 0.06em;
            & + span {
              letter-spacing: -0.1em;
            }
          }
        }
      }
    }
  }
  &__signature {
    padding-left: 13px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 0.11em;
  }
  @include tablet {
    .o-card {
      .wrapper {
        padding: 5px 13px 5px 6px;
      }
    }
  }
  @include sp {
    top: calc(40.8% + 1px);
    &__wrapper {
      width: 295px;
      height: 320px;
      margin-bottom: 58px;
      &:before {
        top: 18px;
        height: calc(100% - 18px);
      }
      &__content {
        padding: 59px 0 75px 0;
        &__logo {
          padding: 0 62px 0 63px;
          margin-bottom: 90px;
        }
        &__google {
          padding: 0 25px 0 30px;
          &__text {
            padding-left: 13px;
            &:before {
              left: 9px;
              top: -5px;
            }
          }
        }
      }
    }
    &__signature {
      padding: 0;
      font-size: 10px;
    }
  }
}
