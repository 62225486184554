@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';
.o-hambuger-menu {
  display: none;

  @include sp {
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    width: 24px;
    &--show &__menu {
      opacity: 1;
      pointer-events: auto;
    }
    &--show &__symbol {
      span {
        &:nth-child(1) {
          transform: translate3d(0, 7px, 0) rotate(45deg);
        }
        &:nth-child(2) {
          width: 0;
        }
        &:nth-child(3) {
          transform: translate3d(6.5px, -7px, 0) rotate(-45deg) scaleX(2.4);
        }
      }
    }
    &__symbol {
      span {
        height: 2px;
        background-color: $color-111;
        display: inline-block;
        position: absolute;
        left: 0;
        transition: all .5s ease-in-out;
        &:nth-child(1) {
          width: 24px;
          top: 18px;
        }
        &:nth-child(2) {
          width: 16px;
          top: 25px;
        }
        &:nth-child(3) {
          width: 10px;
          top: 32px;
        }
      }
    }
    &__menu {
      padding: 16px;
      box-sizing: border-box;
      position: fixed;
      width: 100%;
      background: $white;
      height: 265px;
      left: 0;
      top: 54px;
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s;
      &__item {
        display: flex;
        margin-bottom: 4px;
        & > div {
          margin-left: 12px;
          padding: 0 0 8px 0;
          flex: 1;
          & span {
            font-weight: bold;
            font-size: 14px;
          }
        }
        & button {
          margin-right: 10px;
        }
        & + & + & + & + & + & {
          margin-bottom: 25px;
        }
      }
      &__logout {
        display: flex;
        justify-content: center;
        padding-bottom: 18px;
        margin-right: 5px;
        background: inherit;
      }
      &__backlayer {
        position: fixed;
        background: $color-41;
        content: '';
        width: 100%;
        height: 100vh;
        opacity: 0.5;
        left: 0;
      }
    }
  }
}
