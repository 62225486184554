$black: #000000;
$grey_ee: #eeeeee;
$grey_77: #777777;
$white: #ffffff;
$yellow-pastel: #fff8b9;
$green-pastel: #b6e7c9;
$blue-pastel: #b2e6fa;
$red-pastel: #f4bbbb;
$orange-pastel: #fcdcc1;
$red-dc1e: #dc1e1e;
$color-111: #111;
$color-41: #414141;
$color-f3: #f3f3f3;
$color-dc: #dcdcdc;

$small-sp: 'max-width: 320px';
$middle-sp: 'min-width: 321px) and (max-width: 495px';
$sp: 'max-width: 767px';
$tablet: 'min-width: 768px) and (max-width: 1023px';
$not-sp: 'min-width: 768px';
$pc: 'min-width: 1024px';
$large-pc: 'min-width: 1440px';
