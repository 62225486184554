@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.a-btn {
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  &:focus {
    outline: none;
  }
  &--primary {
    background: $color_111;
    color: $white;
    font-size: 16px;
    font-weight: bold;
    height: 45px;
    position: relative;
    box-sizing: border-box;
    transition: all .4s;

    &::before {
      content: '';
      background: url('../../../assets/imgs/clock.svg') no-repeat;
      width: 24px;
      height: 24px;
      margin-right: 6px;
    }
    &:hover {
      color: $color-111;
      background: $white;
      border: 1px solid;
      &::before {
        background: url('../../../assets/imgs/clock-hover.svg') no-repeat;
      }
    }
  }
  &--secondary {
    background: $white;
    color: $color_111;
    font-size: 16px;
    font-weight: bold;
    height: 40px;
    border: 1px solid $color_111;
    border-radius: 20px;
    width: 100%;
    position: relative;
    letter-spacing: -0.06em;
    transition: all .4s;

    &::before {
      content: '';
      background: url('../../../assets/imgs/light-bulb.svg') no-repeat;
      width: 20px;
      height: 20px;
      background-size: cover;
      margin: 0 5px 0 3px;
    }
    &:hover {
      &::before {
        background: url('../../../assets/imgs/light-bulb-hover.png') no-repeat;
        background-size: cover;
      }
      background: $color-111;
      color: $white;
    }
  }

  &--icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: transparent;
  }
  &--function {
    &:hover {
      background: $color-111;
      img {
        filter: invert(1);
      }
    }
  }

  &--arrow {
    width: 24px;
    height: 24px;
    &:hover {
      background: transparent;
      img {
        filter: none;
      }
    }
  }

  &--floating {
    width: 64px;
    height: 64px;
    border-radius: 64px;
    background: $color-111;
    position: relative;
    border: 1px solid $color-111;
    box-sizing: border-box;
    transition: all .4s;
    &::after {
      content: '';
      background: url(../../../assets/imgs/shadow.png) no-repeat;
      background-size: cover;
      position: absolute;
      z-index: -1;
      bottom: -4px;
      right: -4px;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: white;
      img {
        filter: invert(1);
      }
    }
  }
  &--yellow {
    background: $yellow-pastel;
  }
  &--green {
    background: $green-pastel;
  }
  &--blue {
    background: $blue-pastel;
  }
  &--red {
    background: $red-pastel;
  }
  &--orange {
    background: $orange-pastel;
  }
  &--grey {
    background: $grey-ee;
  }
  &--rect {
    width: 108px;
    height: 108px;
    border: 1px solid $black;
    background: $white;
    font-weight: bold;
    font-size: 18px;
    display: inline-flex;
    flex-direction: column;
    padding: 0;
    letter-spacing: 0.07em;
    transition: all .4s;
    &::after {
      margin: 8px 0 0 5px;
      background: url('../../../assets/imgs/arrow-solid.svg') no-repeat;
      width: 51px;
      height: 12px;
      content: '';
    }
    &:hover {
      background: $color-111;
      color: $white;
      &::after {
        background: url('../../../assets/imgs/arrow-solid-hover.svg') no-repeat;
      }
    }
  }
}
