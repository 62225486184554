@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

@mixin modify($img) {
  &--#{$img} {
    background: url('../../../assets/imgs/'+$img+'.png');
    background-size: cover;
    position: relative;
    cursor: pointer;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url('../../../assets/imgs/'+$img+'-hover.png');
      background-size: cover;
      z-index: 2;
      opacity: 0;
      transition: opacity .4s;
    }
    &:hover::after {
      opacity: 1;
    }
  }
}
.a-function-button {
  width: 40px;
  height: 40px;
  display: block;
  content: '';
  @include modify('mail');
  @include modify('people');
  @include modify('folder');
  @include modify('calendar');
  @include modify('video');
  @include modify('time');
  @include modify('hakusyaku');
  @include modify('agpt');

  @include tablet {
    width: 30px;
    height: 30px;
  }

}
