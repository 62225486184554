@import '../../../assets/css/variables';
@import '../../../assets/css/mixins';

.o-bbs {
  $r: &;
  &__tablist {
    display: flex;
    margin-bottom: 10px;
  }
  .bbs-frame {
    height: 400px;
    iframe {
      border: solid 1px #888;
      width: 100%;
      height: 400px;
    }
  }

  @include tablet {
    &__tablist {
      margin-bottom: 10px;
      .m-tab {
        min-width: 80px;
      }
    }
  }

  @include sp {
    &__tablist {
      margin-bottom: 10px;
      .m-tab {
        min-width: 60px;
      }
    }
  }

}
