@import '../src/assets/css/variables';

body {
  color: $color-111;
  font-size: 14px;
  font-family: 'Barlow', 'Helvetica', 'Arial', YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'メイリオ', 'Meiryo',
    'ＭＳ Ｐゴシック', sans-serif;
  margin: 0;
}

*::before, *::after {
  font-family: 'Barlow', 'Helvetica', 'Arial', YuGothic, 'Yu Gothic', 'ヒラギノ角ゴ Pro W3', 'メイリオ', 'Meiryo',
  'ＭＳ Ｐゴシック', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  appearance: none;
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 14px;
  color: $color-111;
}

a:focus,
button:focus {
  outline: 0;
}
