@import '../../../assets/css/variables';

.a-icon {
  width: 24px;
  &--14 {
    height: 14px;
  }
  &--16 {
    height: 16px;
  }
  &--18 {
    width: 18px;
  }
  &--20 {
    width: 18px;
  }
  &--27 {
    width: 27px;
  }
  &--40 {
    width: 40px;
  }

  &--48 {
    width: 48px;
  }
  &--53 {
    width: 53px;
  }
  &--55 {
    width: 55px;
  }
}
